import React from "react";
import cirsolarLogo from "./assets/images/cirsolarLogo.svg";

//Styles
import "./App.scss";

export default function App() {
  return (
    // bg area
    <div className="bg-area">
      {/* title */}
      <div className="title">cirsolar.cloud</div>

      {/* logo */}
      <div className="logo">
        <img src={cirsolarLogo} alt="cirsolar" />
      </div>
    </div>
  );
}
